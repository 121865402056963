/* eslint-disable */
export const getCurrencies = async () => {
  try {
    const urlPrice = 'https://api.coingecko.com/api/v3/simple/price?ids=cardano&vs_currencies=eur,usd';
    const res = await fetch(urlPrice);
    const data = await res.json();
    return data.cardano;
  } catch (err) {
    // eslint-disable-next-line
    console.log(err);
    return null;
  }
};
