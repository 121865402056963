import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import tw from 'tailwind-styled-components';
import { useQuery } from 'react-query';
import { getYearly } from 'api/annual';
import Loader from 'components/Loader';
import Card, { CardTitle, CardTitleWrap } from 'components/Card';
import { formatDateWithTime } from 'utils/dates';
import { InfoLabel, Value } from '../styled/InfoCard';
import CardTooltip from './CardTooltip';

const InfoCard = tw(Card)`
  lg:w-full
  lg:ml-0
  
  md:w-1/2
  md:ml-2.5

  relative
  min-h-[176px]
`;

const useYearly = (stakeAddress) =>
  useQuery(['yearly', stakeAddress], async () => {
    const res = await getYearly(stakeAddress);
    if (res.error) {
      return null;
    }
    return res;
  });

const YearlyCard = ({ stakeAddress, date }) => {
  const { t } = useTranslation(['wallet', 'common']);

  const { data, isLoading } = useYearly(stakeAddress);

  return (
    <InfoCard>
      <CardTitleWrap>
        <CardTitle>{t('yearly_returns')}</CardTitle>
        <CardTooltip>{t('yearly_returns_tooltip')}</CardTooltip>
      </CardTitleWrap>
      {isLoading && <Loader />}
      {!isLoading && (
        <>
          <InfoLabel>
            {t('common:updated')} {formatDateWithTime(date)}
          </InfoLabel>
          <Value>
            {data ? `${Number(data.apyReturns.toFixed(2))} %` : '-'}
          </Value>
        </>
      )}
    </InfoCard>
  );
};

YearlyCard.propTypes = {
  stakeAddress: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date).isRequired,
};

export default YearlyCard;
