import tw from 'tailwind-styled-components';
import ColorsEnum from 'utils/colors';

export const InfoLabel = tw.p`
  mb-4
  text-sm
  ${(p) => {
    if (p.$color === ColorsEnum.labelOrange) return 'text-labelOrange';
    return p.$color ? `text-${p.$color}` : 'text-label';
  }}  
`;

export const Value = tw.p`
  mb-3
  text-2xl
  font-black
  ${(p) => (p.$color ? `text-${p.$color}` : 'text-orange')}  
`;

export const ExchangeValue = tw.p`
  mb-0
  font-black
  ${(p) => (p.$color ? `text-${p.$color}` : 'text-violet')}  
`;
