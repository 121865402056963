/* eslint-disable */
import config from '../config.json';
import { getHeaders } from './utils';

const payload = {
  method: 'GET',
  headers: getHeaders(),
  redirect: 'follow',
};

/*
    @title: loadHistoryData
    @param: ADA address
    @response: stakeAndRewardList object
*/
export const loadHistoryData = async (_stake_address) => {
  try {
    // Continue with calling the endpoints with _stake_address
    const urlStakeHistory = `${config.baseurl}/accounts/${_stake_address}/history`;
    const urlRewardHistory = `${config.baseurl}/accounts/${_stake_address}/rewards`;

    const results = await Promise.all([
      fetch(urlStakeHistory, payload),
      fetch(urlRewardHistory, payload),
    ]);

    const dataPromises = results.map((result) => result.json());
    const data = await Promise.all(dataPromises);

    const stakeAndRewardList = [];

    const epochsRes = await fetch(`${config.baseurl}/epochs/${data[0][data[0].length - 1].active_epoch}/previous?count=${data[0].length - 1}`, payload);
    const currentRes = await fetch(`${config.baseurl}/epochs/${data[0][data[0].length - 1].active_epoch}`, payload);
    const epochs = await epochsRes.json();
    const current = await currentRes.json()
    epochs.push(current);

    for (let index in data[0], data[1]) {
      stakeAndRewardList.push({
        active_epoch: Number(data[0][index].active_epoch),
        amount_staked: Number((data[0][index].amount / 1000000).toFixed(2)),
        amount_rewarded: Number((data[1][index].amount / 1000000).toFixed(4)),
        start_time: epochs[index].start_time,
        end_time: epochs[index].end_time,
      });
    }
    return stakeAndRewardList;
  } catch (error) {
    console.error(error);
    return { error };
  }
};
