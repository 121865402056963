import { useContext, useState } from 'react';
import tw from 'tailwind-styled-components';
import { useTranslation } from 'react-i18next';
import Input, { ErrorMessage } from 'components/Input';
import Button from 'components/Button';
import useWallet from 'utils/hooks/useWallet';
import Loader from 'components/Loader';
import { WalletContext } from '../Layout';
import MainInfo from './components/MainInfo';
import RewardInfo from './components/RewardInfo';

// region STYLES
const Form = tw.form`
  border-b
  border-label
  mb-10
  pb-6
`;

const InputWrap = tw.div`
  flex
  flex-col
  items-stretch
  
  sm:flex-row
`;

const InputLabel = tw.label`
  text-label
  text-lg
  mb-4
  block
  pt-13
  
  lg:pt-0
`;
// endregion

const Wallet = () => {
  const {
    value: contextValue,
    data,
    error,
    changeContext,
    currencies,
    currentCurrency,
  } = useContext(WalletContext);
  const { t } = useTranslation(['wallet', 'common']);
  const [value, setValue] = useState(contextValue);
  const { onSubmit, isLoading } = useWallet(value, changeContext);

  return (
    <div>
      <Form onSubmit={onSubmit}>
        <InputLabel htmlFor="address">{t('wallet_label')}</InputLabel>
        <InputWrap>
          <Input
            name="address"
            id="address"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            className="mb-2 sm:mb-0"
            $error={error}
          />
          <Button className="sm:ml-4 sm:max-w-50">{t('common:submit')}</Button>
        </InputWrap>
        {error && <ErrorMessage message={error} />}
      </Form>
      <div>
        {isLoading && <Loader />}
        {data && (
          <>
            <MainInfo
              data={data}
              currentCurrency={currentCurrency}
              currencies={currencies}
            />
            <RewardInfo
              data={data}
              currentCurrency={currentCurrency}
              currencies={currencies}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Wallet;
