import tw from 'tailwind-styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CloseButton from 'components/CloseButton';
import { HistoryProps } from 'utils/prop-types/WalletStatisticProps';
import { formatDate } from 'utils/dates';

// region STYLES
const SidebarWrap = tw.aside`
  fixed
  top-0
  right-0
  bottom-0
  flex
  flex-col
  max-w-[440px]
  w-full
  bg-background
  z-50
  ${(p) => (p.$opened ? 'shadow-lg' : 'shadow-none')}
  duration-300
  transform
  ${(p) => (p.$opened ? 'translate-x-0' : 'translate-x-full')}
`;

const SidebarOverlayButton = tw.button`
  fixed
  top-0
  right-0
  bottom-0
  left-0
  z-50
  h-screen
  w-screen
`;

const SidebarHeader = tw.p`
  pl-8
  pr-8
  pt-6
  pb-8
  text-violet
  text-2xl
  font-black
`;

const SidebarContent = tw.div`
  h-full
  overflow-auto
  w-full
  pb-8
  pl-8
  pr-8
`;

const HistoryItem = tw.li`
  pl-6
  pb-8
  relative
`;

const HistoryItemLine = tw.div`
  absolute
  left-2
  bg-gray
  top-1
  -bottom-1
  w-px
`;

const HistoryItemCircle = tw.div`
  bg-gray
  absolute
  flex
  items-center
  justify-center
  h-4
  w-4
  top-[2px]
  left-0
  rounded-lg
`;

const HistoryItemInnerCircle = tw.div`
  bg-background
  w-2
  h-2
  rounded
`;

const ADAWrap = tw.p`
  mb-2
  flex
  flex-wrap
  text-sm
`;

const ADAValue = tw.span`
  text-violet
  font-bold
  whitespace-nowrap
`;

const ADAAmount = tw.span`
  text-orange
  ml-1
`;

const ADADivider = tw.span`
  text-label
  mr-1
  ml-1
`;

const ADADate = tw.p`
  text-label
  text-xs
`;
// endregion

const RewardHistorySidebar = ({ isOpened, onClose, data }) => {
  const { t } = useTranslation('wallet');

  return (
    <>
      {isOpened && <SidebarOverlayButton onClick={onClose} />}
      <SidebarWrap $opened={isOpened}>
        <CloseButton
          className="top-7 right-6"
          onClick={onClose}
          tabIndex={isOpened ? 0 : -1}
        />
        <SidebarHeader>{t('reward_history')}</SidebarHeader>
        <SidebarContent>
          <ul>
            {data.map((item) => (
              <HistoryItem key={item.active_epoch}>
                <HistoryItemLine aria-hidden />
                <HistoryItemCircle aria-hidden>
                  <HistoryItemInnerCircle />
                </HistoryItemCircle>
                <ADAWrap>
                  <ADAValue>
                    {t('reward')}:
                    <ADAAmount>
                      {t('common:ada_label')} {item.amount_rewarded}
                    </ADAAmount>
                  </ADAValue>
                  <ADADivider>|</ADADivider>
                  <ADAValue>
                    {t('stake')}:
                    <ADAAmount>
                      {t('common:ada_label')} {item.amount_staked}
                    </ADAAmount>
                  </ADAValue>
                </ADAWrap>
                <ADADate>
                  {formatDate(item.start_time * 1000)}{' '}
                  - {formatDate(item.end_time * 1000)}
                </ADADate>
              </HistoryItem>
            ))}
          </ul>
        </SidebarContent>
      </SidebarWrap>
    </>
  );
};

RewardHistorySidebar.propTypes = {
  isOpened: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: HistoryProps.isRequired,
};

export default RewardHistorySidebar;
