import PropTypes from 'prop-types';

const { number, shape, arrayOf } = PropTypes;

export const HistoryProps = arrayOf(shape({
  active_epoch: number,
  amount_rewarded: number,
  amount_staked: number,
  end_time: number,
  start_time: number,
}));

export const WalletStatisticProps = shape({
  estimated_next_reward: number,
  value_usd_price: number,
  total_amount: number,
  total_rewards: number,
});

export const CurrenciesProps = shape({
  usd: number,
  eur: number,
});
