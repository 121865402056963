import { createContext, useEffect, useState } from 'react';
import { ChildrenProps } from 'utils/prop-types/UtilProps';
import { Main } from 'components/Columns';
import { getCurrencies } from 'api/currency';
import Sidebar from './components/Sidebar';
import Topbar from './components/Topbar';
import Welcome from './components/Welcome';
import Loader from '../../components/Loader';

export const WalletContext = createContext();

const Layout = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currencies, setCurrencies] = useState(null);
  const [currentCurrency, setCurrentCurrency] = useState('usd');
  const [context, setContext] = useState({
    value: '',
    data: null,
  });

  useEffect(async () => {
    setIsLoading(true);
    const res = await getCurrencies();
    setCurrencies(res);
    setIsLoading(false);
  }, []);

  const changeContext = (newContext) => {
    setContext((prevState) => ({
      ...prevState,
      ...newContext,
    }));
  };

  const contextValue = {
    ...context,
    currencies,
    currentCurrency,
    changeContext,
  };

  return (
    <WalletContext.Provider value={contextValue}>
      <div>
        <Sidebar />
        <Topbar
          setCurrentCurrency={setCurrentCurrency}
          currentCurrency={currentCurrency}
        />
        <Main>
          {context.data ? children : <Welcome context={contextValue} />}
          {isLoading && <Loader />}
        </Main>
      </div>
    </WalletContext.Provider>
  );
};

Layout.propTypes = {
  children: ChildrenProps.isRequired,
};

export default Layout;
