import tw from 'tailwind-styled-components';

const Card = tw.div`
  pt-4
  pl-6
  pr-6
  pb-5
  rounded-lg
  shadow-2xl
  ${(p) => (p.$bg ? `bg-${p.$bg}` : 'bg-background')}  
`;

export const CardTitleWrap = tw.div`
  mb-1
  flex
  justify-between
  items-center
`;

export const CardTitle = tw.p`
  text-2xl
  font-black
  ${(p) => (p.$color ? `text-${p.$color}` : 'text-violet')}  
`;

export default Card;
