/* eslint-disable */
import config from '../config.json';
import { getHeaders } from './utils';

const payload = {
  method: 'GET',
  headers: getHeaders(),
  redirect: 'follow',
};

// TODO: replace with dinamic value
// Epoch number for 01.01.2021
const startOfThisYearEpoch = 238;

export const getYearly = async (stakeAddress) => {
  try {
    /// Endpoints
    const urlReward = `${config.baseurl}/accounts/${stakeAddress}/rewards?count=100&page=1&order=asc`;
    const urlEpoch = `${config.baseurl}/epochs/${startOfThisYearEpoch}/next?count=100&page=1`;
    const urlStake = `${config.baseurl}/accounts/${stakeAddress}/history?count=100&page=1&order=asc`;

    const results = await Promise.all([
      fetch(urlReward, payload),
      fetch(urlEpoch, payload),
      fetch(urlStake, payload),
    ]);

    const dataPromises = results.map((result) => result.json());
    const finalData = await Promise.all(dataPromises);

    let totalRewardsSoFar = 0;
    const rewardsList = [];
    const stakeList = [];
    const yieldList = [];

    for (const i in finalData[0]) {
      const rewardEpoch = finalData[0][i].epoch;
      const rewardAmount = finalData[0][i].amount;
      const stakeAmount = finalData[2][i].amount;

      if (rewardEpoch >= startOfThisYearEpoch) {
        const sum = rewardAmount / 1000000;
        rewardsList.push(sum);
        stakeList.push(stakeAmount / 1000000);
        totalRewardsSoFar += sum;
      }
    }

    const apyData = {
      listOfRewardsSoFar: rewardsList,
      listOfStakeSoFar: stakeList,
      totalRewardSoFar: totalRewardsSoFar,
    };

    for (let i = 0; i < stakeList.length; i++) {
      const stake = apyData.listOfStakeSoFar[i];
      const reward = apyData.listOfRewardsSoFar[i];
      const sum = ((stake + reward) / stake - 1);
      yieldList.push(sum * 10000);
    }

    apyData.yieldList = yieldList;
    let result = 0;
    for (let num in apyData.yieldList) {
      result += apyData.yieldList[num];
    }
    const average = result / apyData.yieldList.length;
    apyData.apyReturns = average;

    return apyData;
  } catch (err) {
    console.error(err);
    return { error };
  }
};
