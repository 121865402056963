import tw from 'tailwind-styled-components';
import PropTypes from 'prop-types';
import { ReactComponent as ErrorIcon } from 'icons/error.svg';

const Input = tw.input`
  border
  ${(p) => (p.$error ? 'border-error' : 'border-border')}
  ${(p) => (p.$error && 'shadow-inputError')}
  h-11
  w-full
  rounded
  pl-4
  pr-4
  text-orange
  text-sm
  duration-300
  
  focus:outline-none
  focus:border-border
  focus:shadow-input
`;

const ErrorMessageText = tw.p`
  mt-2
  text-error
  text-sm
  flex
  items-start
`;

export const ErrorMessage = ({ message }) => (
  <ErrorMessageText>
    <ErrorIcon className="flex-shrink-0 mt-[2px] mr-1" />
    {message}
  </ErrorMessageText>
);

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Input;
