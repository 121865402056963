import tw from 'tailwind-styled-components';

const Button = tw.button`
  bg-orange
  text-background
  rounded
  w-full
  pl-5
  pr-5
  text-lg
  h-11
  font-black
  duration-300
  flex-shrink-0
  
  hover:bg-hoverOrange
  focus:bg-focusOrange
  focus:outline-none
`;

export default Button;
