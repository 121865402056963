import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Card, { CardTitle, CardTitleWrap } from 'components/Card';
import ColorsEnum from 'utils/colors';
import {
  CurrenciesProps,
  WalletStatisticProps,
} from 'utils/prop-types/WalletStatisticProps';
import { Column, Row } from 'components/Columns';
import { convertValue } from 'utils/currencies';
import { formatDateWithTime } from 'utils/dates';
import { ExchangeValue, InfoLabel, Value } from '../styled/InfoCard';
import CardTooltip from './CardTooltip';

const columnClass = 'xl:w-1/4 lg:w-1/3 md:w-1/2';

const MainInfo = ({ data, currentCurrency, currencies }) => {
  const { t } = useTranslation(['wallet', 'common']);

  return (
    <Row className="mb-6">
      <Column className={`mb-5 md:mb-0 ${columnClass}`}>
        <Card>
          <CardTitleWrap>
            <CardTitle>{t('current_balance')}</CardTitle>
            <CardTooltip>{t('current_balance_tooltip')}</CardTooltip>
          </CardTitleWrap>
          <InfoLabel>
            {t('common:updated')} {formatDateWithTime(data.date)}
          </InfoLabel>
          <Value>
            ADA {data.total_amount}
          </Value>
          <ExchangeValue>
            {t(`common:${currentCurrency}_label`)}{' '}
            {convertValue(data.total_amount, currencies[currentCurrency])}
          </ExchangeValue>
        </Card>
      </Column>
      <Column className={columnClass}>
        <Card $bg={ColorsEnum.violet}>
          <CardTitleWrap>
            <CardTitle $color={ColorsEnum.background}>
              {t('total_rewards')}
            </CardTitle>
            <CardTooltip background="label">
              {t('total_rewards_tooltip')}
            </CardTooltip>
          </CardTitleWrap>
          <InfoLabel>
            {t('common:updated')} {formatDateWithTime(data.date)}
          </InfoLabel>
          <Value>
            ADA {data.total_rewards}
          </Value>
          <ExchangeValue $color={ColorsEnum.background}>
            {t(`common:${currentCurrency}_label`)}{' '}
            {convertValue(data.total_rewards, currencies[currentCurrency])}
          </ExchangeValue>
        </Card>
      </Column>
    </Row>
  );
};

MainInfo.propTypes = {
  data: WalletStatisticProps.isRequired,
  currencies: CurrenciesProps.isRequired,
  currentCurrency: PropTypes.string.isRequired,
};

export default MainInfo;
