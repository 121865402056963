import { useState } from 'react';
import { getWalletData } from 'api/wallet';

export default function useWallet(
  value,
  changeContext = () => {},
) {
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await getWalletData(value);
    if (res.error) {
      changeContext({
        error: res.error.message,
        value,
      });
    } else {
      changeContext({
        data: {
          ...res,
          date: new Date(),
        },
        error: null,
        value,
      });
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    onSubmit,
  };
}
