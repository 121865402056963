import { Switch, Route } from 'react-router-dom';
import Layout from './containers/Layout';
import Wallet from './containers/Wallet';

const Routes = () => (
  <Switch>
    <Route path="/">
      <Layout>
        <Switch>
          <Route path="/">
            <Wallet />
          </Route>
        </Switch>
      </Layout>
    </Route>
  </Switch>
);

export default Routes;
