import tw from 'tailwind-styled-components';
import { ReactComponent as CloseIcon } from 'icons/close.svg';

const CloseStyledButton = tw.button`
  absolute
  p-1
  top-8
  right-7
  group
  
  focus:outline-violet
`;

const CloseButtonIcon = tw(CloseIcon)`
  fill-violet
  duration-300
  h-5
  w-5
  
  group-hover:fill-hoverViolet
  group-focus:focusViolet
`;

const CloseButton = ({ ...props }) => (
  <CloseStyledButton type="button" {...props}>
    <CloseButtonIcon />
  </CloseStyledButton>
);

export default CloseButton;
