/* eslint-disable */
import config from '../config.json';
import { getHeaders } from './utils';

const payload = {
  method: 'GET',
  headers: getHeaders(),
  redirect: 'follow',
};

/*
    Load an ADA payment address.
    @title: loadAddressData
    @param: ADA address
    @response: allData object
*/
const loadAddressData = async (address) => {
  try {
    const urlAddress = `${config.baseurl}/addresses/${address}`;
    // Need to assign _stake_address before we can continue
    const res = await fetch(urlAddress, payload);
    const a = await res.json();
    const _stake_address = a.stake_address;

    // Continue with calling the endpoints with _stake_address
    const urlStakeInfo = `${config.baseurl}/accounts/${_stake_address}`;
    const urlRewardHistory = `${config.baseurl}/accounts/${_stake_address}/rewards`;
    const urlEpoch = `${config.baseurl}/epochs/latest`;
  
    const results = await Promise.all([
      fetch(urlStakeInfo, payload),
      fetch(urlRewardHistory, payload),
      fetch(urlEpoch, payload),
    ]);

    const dataPromises = results.map((result) => result.json());
    const data = await Promise.all(dataPromises);

    // Estimates the next reward by calculating the average of the last ten rewards
    // @returns amount_rewarded
    const estimateNextReward = () => {
      let num = 0;
      const lastTenRewards = data[1].slice(-10); // data[1] = urlRewardHistory
      for (let index in lastTenRewards) {
        let amount = Number(lastTenRewards[index].amount);
        num += amount;
      }
      return Number(((num / lastTenRewards.length) / 1000000).toFixed(4));
    };

    // allData object contain all the data needed for the frontend
    const allData = {
      stake_address: _stake_address,
      total_amount: Number((data[0].controlled_amount / 1000000).toFixed(2)),
      total_rewards: Number((data[0].rewards_sum / 1000000).toFixed(4)),
      estimated_next_reward: estimateNextReward(),
      next_reward_date: new Date(data[2].end_time * 1000),
    };

    console.log(allData);
    return allData;
  } catch (error) {
    console.error(error);
    return { error }
  }
};

// Number of characters in the addresses
const paymentAddress = 103;

/* Check if user input is a valid ADA address */
export const getWalletData = async (address) => {
  try {
    if (address.length === paymentAddress) {
      return await loadAddressData(address);
    }
    throw new Error('Wrong address input, only ADA payment addresses are valid inputs');
  } catch (error) {
    return { error };
  }
};
