import { BrowserRouter as Router } from 'react-router-dom';
import 'what-input';
import Modal from 'react-modal';
import { QueryClient, QueryClientProvider } from 'react-query';
import Routes from './Routes';
import './translations/i18n';

const queryClient = new QueryClient();

Modal.setAppElement('#root');

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <div className="App">
          <Routes />
        </div>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
