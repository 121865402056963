import { NavLink } from 'react-router-dom';
import tw from 'tailwind-styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoIcon } from 'icons/logo.svg';

const SidebarWrap = tw.aside`
  absolute
  w-15
  top-0 
  left-0
  z-40
  
  sm:fixed 
  sm:bg-violet
  sm:bottom-0
`;

const Link = tw(NavLink)`
  pl-5
  pr-5 
  flex 
  items-center
  justify-center
  h-full
  w-full
  duration-300
  group
  
  hover:text-hover
  focus:text-hover
`;

const MenuItem = tw.li`
  h-15
  w-full
  relative
`;

const LinkLabel = tw.span`
  duration-300
  absolute 
  bg-text
  opacity-0
  text-background
  duration-300
  h-6
  pl-5
  pr-5
  flex
  items-center
  text-xs
  rounded-sm
  transform
  -translate-y-1/2
  top-1/2
  left-full
  ml-1
  pointer-events-none
  
  group-hover:opacity-100
  group-focus:opacity-100
`;

const LogoWrap = tw.div`
  h-15
  w-15
  flex
  items-center
  justify-center
`;

const Logo = tw(LogoIcon)`
  w-10
  fill-orange
  stroke-orange
  
  sm:fill-label
  sm:stroke-label
`;

const HomeIcon = ({ className }) => (
  <svg viewBox="0 0 24 24" aria-hidden className={className}>
    {/* eslint-disable-next-line max-len */}
    <path d="M8.33415 15.8333V11.6666H11.6675V15.8333C11.6675 16.2916 12.0425 16.6666 12.5008 16.6666H15.0008C15.4591 16.6666 15.8341 16.2916 15.8341 15.8333V9.99998H17.2508C17.6341 9.99998 17.8175 9.52498 17.5258 9.27498L10.5591 2.99998C10.2425 2.71664 9.75915 2.71664 9.44248 2.99998L2.47581 9.27498C2.19248 9.52498 2.36748 9.99998 2.75081 9.99998H4.16748V15.8333C4.16748 16.2916 4.54248 16.6666 5.00081 16.6666H7.50081C7.95915 16.6666 8.33415 16.2916 8.33415 15.8333Z" />
  </svg>
);

HomeIcon.propTypes = {
  className: PropTypes.string.isRequired,
};

const SidebarLink = ({ icon: Icon, title, ...other }) => (
  <MenuItem>
    <Link
      {...other}
      activeClassName=""
    >
      {Icon && (
        <Icon className="w-6 h-6 duration-300 flex-shrink-0 fill-background" />
      )}
      <LinkLabel>{title}</LinkLabel>
    </Link>
  </MenuItem>
);

SidebarLink.propTypes = {
  icon: PropTypes.node,
  title: PropTypes.string,
};

SidebarLink.defaultProps = {
  icon: null,
  title: '',
};

const Sidebar = () => {
  const { t } = useTranslation('sidebar');

  return (
    <SidebarWrap>
      <LogoWrap>
        <Logo />
      </LogoWrap>
      {false && (
        <ul>
          <SidebarLink exact to="/" title={t('wallet')} icon={HomeIcon} />
        </ul>
      )}
    </SidebarWrap>
  );
};

export default Sidebar;
