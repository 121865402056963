const ColorsEnum = {
  violet: 'violet',
  orange: 'orange',
  labelOrange: 'labelOrange',
  hoverViolet: 'hoverViolet',
  hoverOrange: 'hoverOrange',
  text: 'text',
  background: 'background',
  border: 'border',
  label: 'label',
  transparent: 'transparent',
};

export default ColorsEnum;
