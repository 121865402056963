import { useTranslation } from 'react-i18next';
import tw from 'tailwind-styled-components';
import { useState } from 'react';
import Modal from 'react-modal';
import Card from 'components/Card';
import Input, { ErrorMessage } from 'components/Input';
import CloseButton from 'components/CloseButton';
import Loader from 'components/Loader';
import { submitEmailForm } from 'api/email';

import { ReactComponent as WalletIcon } from 'icons/wallet.svg';
import { ReactComponent as PlaneIcon } from 'icons/plane.svg';
import { ReactComponent as Illustration } from './wallet-illustration.svg';
import { ReactComponent as SuccessIllustration } from './ok-illustration.svg';

// region STYLES
const WalletButton = tw.button`
  pl-2
  pr-2
  text-violet
  font-black
  text-xs
  duration-300
  flex
  items-center
  whitespace-nowrap
  group
  
  hover:text-hoverViolet
  focus:text-focusViolet
  focus:outline-orange
`;

const WalletIconTw = tw(WalletIcon)`
  duration-300
  fill-orange
  w-6
  h-6
  mr-1
  
  group-hover:fill-hoverOrange
  group-focus:fill-focusOrange
`;

const contentClasses = `
  max-w-[860px]
  w-full
  max-h-screen
  overflow-auto
  shadow-2xl
  rounded-3xl
`;

const overlayClasses = `
  fixed
  bg-background
  bg-opacity-50
  z-50
  left-0
  bottom-0
  top-0
  right-0
  pl-4
  pr-4
  pt-4
  pb-4
  flex
  items-center
  justify-center
`;

const ModalCard = tw(Card)`
  flex
  flex-col
  items-center
  text-center
  shadow-none
  pt-16
  pb-18
  relative
  rounded-3xl
`;

const Title = tw.p`
  text-violet
  text-4xl
  font-black
  mb-2
  max-w-[360px]
`;

const Message = tw.p`
  text-sm
  text-label
  mb-4
  max-w-[360px]
`;

const EmailForm = tw.form`
  relative
  w-full
  max-w-[360px]
`;

const EmailInput = tw(Input)`
  ${(p) => (p.$error ? '!border-error' : 'border-violet')}
  ${(p) => (p.$error && '!shadow-inputError')}
`;

const EmailButton = tw.button`
  text-background
  ${(p) => (p.$error ? 'bg-error' : 'bg-violet')}
  absolute
  right-0
  rounded-r
  h-full
  w-10
  flex
  items-center
  justify-center
  top-0
  duration-300
  
  hover:${(p) => (p.$error ? 'bg-error' : 'bg-hoverViolet')}
  focus:${(p) => (p.$error ? 'bg-error' : 'bg-focusViolet')}
  focus:outline-none
`;
// endregion

const ConnectWalletModal = () => {
  const { t } = useTranslation('common');
  const [isLoading, setIsLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [response, setResponse] = useState(null);

  const onEmailChange = (e) => {
    setEmail(e.target.value);
    setResponse(null);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const res = await submitEmailForm(email);
    setResponse(res);
    setIsLoading(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setResponse(null);
    setEmail('');
  };

  const isSuccess = response && response.status === 200;
  const isError = response && response.status !== 200;

  return (
    <>
      <WalletButton onClick={openModal}>
        <WalletIconTw /> {t('connect_wallet')}
      </WalletButton>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={contentClasses}
        overlayClassName={overlayClasses}
      >
        <ModalCard>
          <CloseButton onClick={closeModal} />
          {isSuccess ? (
            <SuccessIllustration className="max-w-[280px] w-full" />
          ) : (
            <Illustration className="max-w-[360px] w-full" />
          )}
          <Title>{t(isSuccess ? 'success' : 'coming_soon')}</Title>
          <Message>
            {t(isSuccess ? 'success_message' : 'coming_soon_message')}
          </Message>
          {!isSuccess && (
            <>
              <EmailForm onSubmit={onSubmit}>
                <EmailInput
                  required
                  type="email"
                  onChange={onEmailChange}
                  value={email}
                  placeholder={t('email_placeholder')}
                  $error={isError}
                />
                <EmailButton $error={isError}><PlaneIcon /></EmailButton>
                {isLoading && <Loader />}
              </EmailForm>
              {isError && (
                <ErrorMessage message={response.data.message} />
              )}
            </>
          )}
        </ModalCard>
      </Modal>
    </>
  );
};

export default ConnectWalletModal;
