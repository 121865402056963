import tw from 'tailwind-styled-components';
import PropTypes from 'prop-types';
import { ChildrenProps } from 'utils/prop-types/UtilProps';

import { ReactComponent as Icon } from 'icons/info.svg';

const InfoIcon = tw(Icon)`
  h-5
  w-5
  ${(p) => (p.$fill ? `fill-${p.$fill}` : 'fill-orange')}
`;

const TooltipWrap = tw.div`
  h-6
  w-6
  flex
  justify-center
  items-center
  relative
  ml-3
  rounded
  group
  
  focus:outline-none
  focus:border
  ${(p) => (p.$fill ? `focus:border-${p.$fill}` : 'focus:border-orange')}
`;

const Tooltip = tw.div`
  pointer-events-none
  p-3
  text-background
  absolute
  bottom-full
  mb-2
  rounded
  w-43
  -right-9
  text-xs
  ${(p) => (p.$bg ? `bg-${p.$bg}` : 'bg-text')}
`;

const TooltipTriangle = tw.div`
  w-0
  h-0
  border
  border-t-12
  border-l-8
  border-r-8
  border-b-0
  ${(p) => (p.$bg ? 'border-triangleBottomlabel' : 'border-triangleBottom')}
  absolute
  left-1/2
  transform
  -translate-x-1/2
  bottom-full
`;

const TooltipContent = tw.div`
  opacity-0
  duration-300
  
  group-hover:opacity-100
  group-focus:opacity-100
`;

const CardTooltip = ({ fill, background, children }) => (
  <TooltipWrap $fill={fill} tabIndex={0}>
    <InfoIcon $fill={fill} />
    <TooltipContent>
      <Tooltip $bg={background}>
        {children}
      </Tooltip>
      <TooltipTriangle aria-hidden $bg={background} />
    </TooltipContent>
  </TooltipWrap>
);

CardTooltip.propTypes = {
  fill: PropTypes.string,
  background: PropTypes.string,
  children: ChildrenProps,
};

CardTooltip.defaultProps = {
  fill: '',
  background: '',
  children: null,
};

export default CardTooltip;
