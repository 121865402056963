import tw from 'tailwind-styled-components';

export const Main = tw.main`
  relative
  pl-5
  pt-4
  pr-5
  pb-6
  
  sm:ml-15
`;

export const Row = tw.div`
  -ml-2.5
  -mr-2.5
  flex
  flex-wrap
`;

export const Column = tw.div`
  pl-2.5
  pr-2.5
  w-full
  flex-shrink-0
`;
