import tw from 'tailwind-styled-components';
import Chart from 'react-google-charts';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';

// region STYLES
const ChartWrap = tw.div`
  overflow-x-auto
  overflow-y-hidden
`;

const ChartContent = tw.div`
  ${(p) => p.$width || ''}
  pb-2
`;

const options = {
  colors: ['#210854', '#EFEFEF'],
  chartArea: {
    width: '100%',
    height: '90%',
  },
  annotations: {
    stem: {
      color: 'transparent',
      length: -18,
    },
    textStyle: {
      color: '#210854',
      fontSize: 12,
      fontWeight: 900,
    },
  },
  series: {
    0: {
      annotations: {
        alwaysOutside: true,
        stem: {
          color: 'transparent',
          length: 5,
        },
        textStyle: {
          color: '#210854',
          fontSize: 12,
          fontWeight: 500,
        },
      },
    },
    1: {
      annotations: {
        alwaysOutside: true,
        stem: {
          color: 'transparent',
          length: 5,
        },
        textStyle: {
          color: '#646464',
          fontSize: 12,
          fontWeight: 500,
        },
      },
    },
  },
  hAxis: {
    minValue: 0,
    baselineColor: '#EBEBEB',
  },
  vAxis: {
    baselineColor: '#EBEBEB',
    gridlines: { color: '#EBEBEB' },
    minorGridlines: { color: 'transparent' },
    textPosition: 'none',
  },
  legend: { position: 'none' },
  enableInteractivity: false,
  isStacked: true,
  explorer: {
    axis: 'horizontal',
    keepInBounds: true,
    maxZoomIn: 4.0,
  },
};
// endregion

const RewardHistoryChart = ({ chartData, width }) => (
  <ChartWrap>
    <ChartContent $width={width}>
      <Chart
        width="100%"
        height={350}
        chartType="ColumnChart"
        loader={Loader()}
        data={chartData}
        options={options}
      />
    </ChartContent>
  </ChartWrap>
);

RewardHistoryChart.propTypes = {
  chartData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape(),
      ]),
    ),
  ).isRequired,
  width: PropTypes.string,
};

RewardHistoryChart.defaultProps = {
  width: '',
};

export default RewardHistoryChart;
