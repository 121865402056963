import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import tw from 'tailwind-styled-components';
import Card, { CardTitle, CardTitleWrap } from 'components/Card';
import ColorsEnum from 'utils/colors';
import {
  CurrenciesProps,
  WalletStatisticProps,
} from 'utils/prop-types/WalletStatisticProps';
import { Column, Row } from 'components/Columns';
import { convertValue } from 'utils/currencies';
import { formatDateWithTime } from 'utils/dates';
import { ExchangeValue, InfoLabel, Value } from '../styled/InfoCard';
import RewardHistory from './RewardHistory';
import CardTooltip from './CardTooltip';
import YearlyCard from './YearlyCard';

const ChartColumn = tw(Column)`
  xl:w-1/2
  
  lg:w-2/3
  lg:mb-0
  
  mb-5
`;

const CardsColumn = tw(Column)`
  xl:w-1/4
  
  lg:w-1/3 
  lg:pt-10 
  lg:pb-10 
  lg:block
  
  md:flex
  
  z-10
`;

const RewardInfo = ({ data, currentCurrency, currencies }) => {
  const { t } = useTranslation(['wallet', 'common']);

  return (
    <Row>
      <ChartColumn>
        <RewardHistory stakeAddress={data.stake_address} />
      </ChartColumn>
      <CardsColumn>
        <Card
          $bg={ColorsEnum.orange}
          className="lg:w-full lg:mb-8 lg:mr-0 md:w-1/2 md:mb-0 md:mr-2.5 mb-5"
        >
          <CardTitleWrap>
            <CardTitle $color={ColorsEnum.background}>
              {t('next_reward')}
            </CardTitle>
            <CardTooltip fill={ColorsEnum.background}>
              {t('next_reward_tooltip')}
            </CardTooltip>
          </CardTitleWrap>
          <InfoLabel $color={ColorsEnum.labelOrange}>
            {t('common:expectation_date')}{' '}
            {formatDateWithTime(data.next_reward_date)}
          </InfoLabel>
          <Value $color={ColorsEnum.background}>
            ADA {data.estimated_next_reward}
          </Value>
          <ExchangeValue $color={ColorsEnum.labelOrange}>
            {t(`common:${currentCurrency}_label`)}{' '}
            {convertValue(
              data.estimated_next_reward,
              currencies[currentCurrency],
            )}
          </ExchangeValue>
        </Card>
        <YearlyCard stakeAddress={data.stake_address} date={data.date} />
      </CardsColumn>
    </Row>
  );
};

RewardInfo.propTypes = {
  data: WalletStatisticProps.isRequired,
  currencies: CurrenciesProps.isRequired,
  currentCurrency: PropTypes.string.isRequired,
};

export default RewardInfo;
