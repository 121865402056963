/* eslint-disable camelcase */
import sidebar_en from './en/sidebar.json';
import wallet_en from './en/wallet.json';
import common_en from './en/common.json';

export default {
  en: {
    sidebar: sidebar_en,
    wallet: wallet_en,
    common: common_en,
  },
};
