import { useEffect, useState } from 'react';
import tw from 'tailwind-styled-components';
import { ReactComponent as ChevronDownIcon } from 'icons/chevron-down.svg';

const DropdownWrap = tw.div`
  relative
  flex
  items-stretch
`;

const DropdownToggle = tw.button`
  pl-2
  pr-2
  text-orange
  font-black
  text-xs
  duration-300
  flex
  items-center
  whitespace-nowrap
  group
  
  hover:text-hoverOrange
  focus:text-focusOrange
  focus:outline-orange
`;

export const DropdownMenu = tw.ul`
  rounded
  shadow-md
  bg-background
  absolute
  w-full
  top-full
  right-0
  min-w-16
  pt-1
  pb-1
  transform
  -translate-y-1
`;

const DropdownItemTw = tw.li`
  text-xs
  flex
  items-stretch
  h-6
`;

export const DropdownDivider = tw.div`
  w-full
  h-px
  bg-label
  opacity-30
`;

const DropdownItemButton = tw.button`
  w-full
  text-violet
  duration-300
  font-black

  hover:text-hoverViolet
  focus:text-focusViolet
  focus:outline-violet
`;

// eslint-disable-next-line react/prop-types
export const DropdownItem = ({ children, onClick }) => (
  <DropdownItemTw>
    <DropdownItemButton type="button" onClick={onClick}>
      {children}
    </DropdownItemButton>
  </DropdownItemTw>
);

// eslint-disable-next-line react/prop-types
const Dropdown = ({ title, children, className }) => {
  const [isListOpen, setIsListOpen] = useState(false);

  const toggleList = (e) => {
    e.stopPropagation();
    setIsListOpen((prevState) => !prevState);
  };

  useEffect(() => {
    setTimeout(() => {
      if (isListOpen) {
        window.addEventListener('click', () => setIsListOpen(false));
      } else {
        window.removeEventListener('click', () => setIsListOpen(false));
      }
    }, 0);
  }, [isListOpen]);

  return (
    <DropdownWrap className={className} onClick={(e) => e.stopPropagation()}>
      <DropdownToggle type="button" onClick={toggleList}>
        {title} <ChevronDownIcon />
      </DropdownToggle>
      {isListOpen && children}
    </DropdownWrap>
  );
};

export default Dropdown;
