import tw from 'tailwind-styled-components';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-query';
import { loadHistoryData } from 'api/rewards';
import Card, { CardTitle } from 'components/Card';
import Loader from 'components/Loader';

import { ReactComponent as HistoryIcon } from 'icons/history.svg';

import RewardHistoryChart
  from 'containers/Wallet/components/RewardHistoryChart';
import RewardHistorySidebar from './RewardHistorySidebar';

const PERIODS = [{
  label: '1m',
  width: 'min-w-[380px]',
  monthsAmount: 1,
}, {
  label: '3m',
  monthsAmount: 3,
}, {
  label: '6m',
  width: 'min-w-[300px]',
  monthsAmount: 6,
}, {
  label: 'year',
  width: 'min-w-[600px]',
  monthsAmount: 12,
}];

// region STYLES
const RewardHistoryCard = tw(Card)`
  h-full
  relative
  pb-8
  
  lg:pr-18
  lg:pb-13
  lg:pl-11
  lg:pt-9
  lg:-mr-18
`;

const HeaderWrap = tw.div`
  flex
  flex-wrap
  justify-between
  items-stretch
  mb-4
  -mr-2
`;

const FilterButtonsWrap = tw.div`
  grid
  grid-cols-4
  rounded-lg
  overflow-hidden
  h-8
  mb-3
`;

const FilterButton = tw.button`
  sm:pr-4
  sm:pl-4

  ${(p) => (p.$active ? 'bg-orange' : 'bg-gray')}
  ${(p) => (p.$active ? 'text-background' : 'text-orange')}
  duration-300
  text-xs
  font-black
  pr-2
  pl-2
  h-full
  
  hover:bg-orange
  focus:bg-orange
  hover:text-background
  focus:text-background
  focus:outline-none
`;

const ButtonsWrap = tw.div`
  flex
  flex-wrap
  mr-2
`;

const AllButton = tw.button`
  text-label
  text-xs
  font-bold
  duration-300
  flex
  items-center
  group
  mb-3
  whitespace-nowrap
  pr-2
  
  hover:text-orange
  focus:text-orange
  focus:outline-orange
`;

const AllButtonIcon = tw(HistoryIcon)`
  fill-label
  duration-300
  
  group-hover:fill-orange
  group-focus:fill-orange
`;
// endregion

const formatHistory = (history) => {
  if (!history.length) {
    return null;
  }
  const formattedHistory = history.map((item) => ([
    '',
    item.active_epoch.toString(),
    Number(item.amount_rewarded),
    // eslint-disable-next-line max-len
    Number(item.amount_rewarded) ? `+ ₳${item.amount_rewarded.toFixed()}` : '',
    Number(item.amount_staked),
    `₳${Number(item.amount_staked).toFixed()}`,
  ]));

  const header = [
    '',
    { role: 'annotation' },
    'reward',
    { role: 'annotation' },
    'stake',
    { role: 'annotation' },
  ];
  formattedHistory
    .splice(0, 0, header);

  return formattedHistory;
};

const useHistory = (stakeAddress) =>
  useQuery(['history', stakeAddress], async () => {
    const res = await loadHistoryData(stakeAddress);
    if (res.error) {
      return null;
    }
    return res;
  });

const RewardHistory = ({ stakeAddress }) => {
  const { t } = useTranslation('wallet');
  const [period, setPeriod] = useState(PERIODS[0]);
  const [isOpened, setIsOpened] = useState(false);
  const [chartData, setChartData] = useState(null);

  const { data, isLoading } = useHistory(stakeAddress);

  const onPeriodChange = (newPeriod) => () => {
    setPeriod(newPeriod);
    let items = [];

    if (newPeriod.label !== '1m') {
      Array(newPeriod.monthsAmount).fill(0).forEach((v, i) => {
        let date = new Date(new Date().setHours(0, 0, 0, 0));
        const start = new Date(date.setMonth(date.getMonth() - (i + 1), 1));
        date = new Date(new Date().setHours(0, 0, 0, 0));
        const end = new Date(date.setMonth(date.getMonth() - i, 1));
        const monthData = {
          amount_staked: 0,
          amount_rewarded: 0,
        };
        data
          .filter((item) => new Date(item.end_time * 1000) > start
            && new Date(item.end_time * 1000) < end)
          .forEach((item) => {
            monthData.amount_staked = item.amount_staked;
            monthData.amount_rewarded += item.amount_rewarded;
          });

        items.push({
          ...monthData,
          active_epoch: start.toLocaleString(
            'en',
            { month: 'short', year: '2-digit' },
          ).replace(' ', '\''),
        });
      });
      items.reverse();
    } else {
      let date = new Date(new Date().setHours(0, 0, 0, 0));
      date = new Date(date.setMonth(date.getMonth() - 1));
      items = data
        .filter((item) => new Date(item.end_time * 1000) > date)
        .map((item) => ({
          ...item,
          active_epoch: `Epoch ${item.active_epoch}`,
        }));
    }

    setChartData(formatHistory(items));
  };

  useEffect(() => {
    if (data) {
      onPeriodChange(period)();
    }
  }, [data]);

  const onSidebarOpen = () => setIsOpened(true);
  const onSidebarClose = () => setIsOpened(false);

  return (
    <>
      <RewardHistoryCard>
        <HeaderWrap>
          <CardTitle className="mr-2 mb-3">{t('reward_history')}</CardTitle>
          {!isLoading && chartData && (
            <ButtonsWrap>
              <FilterButtonsWrap>
                {PERIODS.map((value) => (
                  <FilterButton
                    key={value.label}
                    $active={value.label === period.label}
                    onClick={onPeriodChange(value)}
                  >
                    {t(`common:${value.label}`)}
                  </FilterButton>
                ))}
              </FilterButtonsWrap>
              <AllButton onClick={onSidebarOpen}>
                <AllButtonIcon />
                {t('see_all')}
              </AllButton>
            </ButtonsWrap>
          )}
        </HeaderWrap>
        {!isLoading && !chartData?.length && (
          <p>{t('common:no_data')}</p>
        )}
        {isLoading && <Loader />}
        {!isLoading && chartData && (
          <RewardHistoryChart width={period.width} chartData={chartData} />
        )}
      </RewardHistoryCard>
      {!isLoading && data?.length && (
        <RewardHistorySidebar
          isOpened={isOpened}
          onClose={onSidebarClose}
          data={data}
        />
      )}
    </>
  );
};

RewardHistory.propTypes = {
  stakeAddress: PropTypes.string.isRequired,
};

export default RewardHistory;
