/* eslint-disable max-len */
import config from '../config.json';

const url = `https://api.hsforms.com/submissions/v3/integration/submit/${config.form.portalId}/${config.form.formId}`;

// eslint-disable-next-line import/prefer-default-export
export const submitEmailForm = async (email) => {
  const data = {
    fields: [
      {
        name: 'email',
        value: email,
      },
    ],
  };

  const body = JSON.stringify(data);
  const payload = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    redirect: 'follow',
    body,
  };

  const res = await fetch(url, payload);

  return {
    status: res.status,
    data: await res.json(),
  };
};
