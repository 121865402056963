import PropTypes from 'prop-types';

const {
  oneOfType,
  arrayOf,
  node,
  string,
} = PropTypes;

// eslint-disable-next-line import/prefer-default-export
export const ChildrenProps = oneOfType([arrayOf(node), node, string]);
