import tw from 'tailwind-styled-components';
import PropTypes from 'prop-types';
import Dropdown, {
  DropdownDivider,
  DropdownItem,
  DropdownMenu,
} from 'components/Dropdown';
import ConnectWalletModal from './ConnectWalletModal';

const TopbarWrap = tw.header`
  flex
  h-15
  pl-5
  pr-5
  items-stretch
  absolute
  top-0
  right-0 
  left-15 
  z-30
  justify-end
`;

const Topbar = ({ currentCurrency, setCurrentCurrency }) => (
  <TopbarWrap>
    <ConnectWalletModal />
    <Dropdown className="sm:ml-6" title={currentCurrency.toUpperCase()}>
      <DropdownMenu>
        <DropdownItem
          onClick={() => setCurrentCurrency('usd')}
        >
          USD
        </DropdownItem>
        <DropdownDivider />
        <DropdownItem
          onClick={() => setCurrentCurrency('eur')}
        >
          EUR
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  </TopbarWrap>
);

Topbar.propTypes = {
  currentCurrency: PropTypes.string.isRequired,
  setCurrentCurrency: PropTypes.func.isRequired,
};

export default Topbar;
