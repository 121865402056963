/* eslint-disable react/style-prop-object,max-len */
import tw from 'tailwind-styled-components';

const StyledPath = tw.path`
  fill-orange
`;

const LoaderWrap = tw.div`
  absolute
  top-0
  left-0
  right-0
  bottom-0
  transform
  bg-background
  flex
  items-center
  justify-center
  bg-opacity-50
  z-10
`;

export const LoadingSvg = () => (
  <svg
    version="1.1"
    id="loader-1"
    x="0px"
    y="0px"
    width="40px"
    height="40px"
    viewBox="0 0 50 50"
    xmlSpace="preserve"
  >
    <StyledPath d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 25 25"
        to="360 25 25"
        dur="0.6s"
        repeatCount="indefinite"
      />
    </StyledPath>
  </svg>
);

const Loader = () => (
  <LoaderWrap>
    <LoadingSvg />
  </LoaderWrap>
);

export default Loader;
