import tw from 'tailwind-styled-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import useWallet from 'utils/hooks/useWallet';
import Input, { ErrorMessage } from 'components/Input';
import Button from 'components/Button';
import Loader from 'components/Loader';

const FormWrap = tw.div`
  min-h-[50vh]
  pb-6
  pt-15
  flex
  justify-end
  flex-col
  max-w-[1100px]
`;

const InputWrap = tw.div`
  flex
  flex-col
  items-stretch
  
  sm:flex-row
`;

const InputLabel = tw.label`
  text-violet
  mb-3
  block
  max-w-[650px]
  
  sm:mb-6
`;

const WelcomeText = tw.p`
  text-lg
  text-violet
  font-semibold
  mb-3
  max-w-[650px]
  
  sm:mb-4
`;

const KnowText = tw.p`
  text-2xl
  text-orange
  font-semibold
  mb-4
  max-w-[650px]
  
  sm:mb-7
  sm:text-3xl
`;

const Welcome = ({ context }) => {
  const { t } = useTranslation(['wallet', 'common']);
  const [value, setValue] = useState(context.value);
  const {
    onSubmit, isLoading,
  } = useWallet(value, context.changeContext);

  return (
    <>
      <FormWrap>
        <form className="w-full" onSubmit={onSubmit}>
          <WelcomeText>{t('welcome')}</WelcomeText>
          <KnowText>{t('want_to_know')}</KnowText>
          <InputLabel htmlFor="address">{t('welcome_wallet_label')}</InputLabel>
          <InputWrap>
            <Input
              name="address"
              id="address"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="mb-2 sm:mb-0"
              $error={context.error}
            />
            <Button className="sm:ml-4 sm:max-w-50">
              {t('common:submit')}
            </Button>
          </InputWrap>
          {context.error && <ErrorMessage message={context.error} />}
        </form>
      </FormWrap>
      {isLoading && <Loader />}
    </>
  );
};

Welcome.propTypes = {
  context: PropTypes.shape({
    value: PropTypes.string,
    changeContext: PropTypes.func,
    error: PropTypes.string,
  }).isRequired,
};

export default Welcome;
